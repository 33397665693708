import dayjs from "dayjs";
import { ElNotification } from "element-plus";

export function formatDate(value, mode = "date") {
    if (value) {
        if (mode === "date") {
            return dayjs(value).format("YYYY-MM-DD");
        }
        if (mode === "time") {
            return dayjs(value).format("HH:mm:ss");
        }
        if (mode === "hour") {
            return dayjs(value).format("HH:mm");
        }
        if (mode === "datetime") {
            return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
        }
    }
}

/**
 * 去除对象空值
 * @param {*} obj
 * @returns
 */
export function removeEmpty(obj) {
    const newObj = {};
    for (const key in obj) {
        if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}

/**
 * 头像地址
 * @param {*} url
 */
export function avatarUrl(url) {
    if (url) {
        return url;
    }
    return "/favicon.ico";
}

/**
 * 复制文本
 */
export function copyText(text) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
        ElNotification({
            title: "复制成功",
            message: text,
            type: "success",
        });
    } else {
        const input = document.createElement("input");
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
        ElNotification({
            title: "复制成功",
            message: text,
            type: "success",
        });
    }
}

/**
 * 计算过期时间
 * 过期时间为1年，精确到月份
 * @param {string} sn /^(SUP\d{2}[A-Za-z]{1,2})(\d{4})/
 * @returns {string} YYYY-MM
 */
export function getExpireDate(sn) {
    const match = sn?.replace(" ", "").match(/^(SUP\d{2}[A-Za-z]{1,2})(\d{4})/);
    if (!match) return "";
    const ym = match[2];
    const year = ym.slice(0, 2);
    const month = ym.slice(2, 4);
    const date = new Date();
    date.setFullYear(`20${year}`);
    date.setMonth(month - 1);
    date.setMonth(date.getMonth() + 12);
    return dayjs(date).format("YYYY年MM月");
}

// 多字段排序
export function sortBy(filed1, filed2) {
    return function (a, b) {
        if (a[filed1] === b[filed1]) {
            return a[filed2].localeCompare(b[filed2]);
        }
        return a[filed1].localeCompare(b[filed1]);
    };
}

export function buffer2Time(buffer, mode = "date") {
    // 将Buffer数据转换为一个64位整数
    const hexString = buffer.data.map((byte) => byte.toString(16).padStart(2, "0")).join("");
    const timestamp = parseInt(hexString, 16);

    // 考虑东八区时间的偏移量（加8小时）
    const unixTimestampSeconds = timestamp + 8 * 60 * 60;

    // 创建JavaScript日期时间对象
    const date = new Date(unixTimestampSeconds * 1000); // 乘以1000将秒数转换为毫秒数
    return formatDate(date, mode);
}

// 是否只保留remainKey
export function arr2map({ array = [], key = "key", remainKey = "" } = {}) {
    if (
        !array?.length ||
        !array.every((item) => key in item) ||
        (remainKey && !array.every((item) => remainKey in item))
    ) {
        console.log("【参数错误】", array, key, remainKey);
        return {};
    }
    return array.reduce((acc, cur) => {
        return { ...acc, [cur[key]]: remainKey ? cur[remainKey] : cur };
    }, {});
}

export function map2arr(map) {
    return Object.entries(map).map(([key, value]) => {
        return {
            name: value,
            key: key,
        };
    });
}
// 去除html标签
export function removeHTMLTags(input) {
    return input.replace(/<[^>]*>/g, "");
}
